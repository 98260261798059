import React from "react"
import imgPortadaGuide from "@components/pagePlantillas/plantillas/plantilla5/image/imgPortada.png"
import img1 from "@components/pagePlantillas/plantillas/plantilla5/image/img.png"
import silder1 from "@components/pagePlantillas/plantillas/plantilla5/image/silder1.png"
import silder2 from "@components/pagePlantillas/plantillas/plantilla5/image/silder2.png"
import silder3 from "@components/pagePlantillas/plantillas/plantilla5/image/silder3.png"

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)


const data = {
  start: {
    support: "Plantilla | Atención al client",
    title: <h1 className="titles-underlined-ebook ebook-ventas">
      10 encuestas de satisfacción al cliente en empresas de servicios
    </h1>,
    description: <p className="text-h2-ebook guide__description">
      Las encuestas de satisfacción permiten conocer la opinión de los clientes en cada etapa del servicio. Usa preguntas simples que ayuden a identificar puntos de mejora. Evalúa tiempos de respuesta, calidad del servicio y claridad en la comunicación. Incluye escalas de valoración para medir la experiencia en cada canal.
    </p>,
    classtext: "guide__description1",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Experiencia General del Cliente.",
      },
      {
        key: 2,
        text: "Calidad de la Interacción.",
      },
      {
        key: 3,
        text: "Tiempo de respuesta.",
      },
      {
        key: 4,
        text: "Comunicación y Transparencia.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: silder1,
      },
      {
        key: 2,
        img: silder2,
      },
      {
        key: 3,
        img: silder3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: <p>
          Recuerda que, con esta guía, podrás ver ejemplos listos para usar en diferentes situaciones. Aplica encuestas después de una atención, al cerrar un caso o tras una compra.
          <br /><br />
          Por último, descubre más detalles, ejemplos prácticos y herramientas recomendadas descargándola. Mejora tus procesos con información basada en datos reales.
        </p>,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
